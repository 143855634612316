// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --duration: 10s;
  --gap: 1rem;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}
@keyframes marquee-vertical {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(calc(-100% - var(--gap)));
  }
}
.marquee-animation {
  animation: marquee var(--duration) linear infinite;
}

.marquee-vertical-animation {
  animation: marquee-vertical var(--duration) linear infinite;
}`, "",{"version":3,"sources":["webpack://./src/mq/mq.scss"],"names":[],"mappings":"AACA;EACI,eAAA;EACA,WAAA;AAAJ;;AAIE;EACE;IACE,wBAAA;EADJ;EAGE;IACE,+CAAA;EADJ;AACF;AAKE;EACE;IACE,wBAAA;EAHJ;EAKE;IACE,+CAAA;EAHJ;AACF;AAOE;EACE,kDAAA;AALJ;;AAQE;EACE,2DAAA;AALJ","sourcesContent":["// Define default values for custom properties\n:root {\n    --duration: 10s;\n    --gap: 1rem;\n  }\n  \n  // Keyframes for marquee animation\n  @keyframes marquee {\n    from {\n      transform: translateX(0);\n    }\n    to {\n      transform: translateX(calc(-100% - var(--gap)));\n    }\n  }\n  \n  // Keyframes for vertical marquee animation\n  @keyframes marquee-vertical {\n    from {\n      transform: translateY(0);\n    }\n    to {\n      transform: translateY(calc(-100% - var(--gap)));\n    }\n  }\n  \n  // Animation classes\n  .marquee-animation {\n    animation: marquee var(--duration) linear infinite;\n  }\n  \n  .marquee-vertical-animation {\n    animation: marquee-vertical var(--duration) linear infinite;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
